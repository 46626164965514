import React from 'react';
import { Container, Row, Col, Alert } from "react-bootstrap";
import firebase from "firebase";

import "./App.scss";

class App extends React.Component {

  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.state = {
      domain:  typeof window !== 'undefined' ? window.location.hostname : '',
      buyername: '',
      mobile: '',
      email: '',
      price: '',
      currency: 'usd',
      message: '',
      submitted: false,
      successfull: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.saveRecord = this.saveRecord.bind(this);
  }

  handleSubmit() {
    const record = {
      datetime: (new Date()).toString(),
      ...this.state
    };
    delete record.submitted;
    delete record.successfull;
    this.saveRecord(record);
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const componentName = target.name;

    this.setState({
      [componentName]: value
    });
  }

  saveRecord(record) {
    const that = this;
    const id = Date.now();
    firebase.database()
            .ref('records/'+ id)
            .set({...record})
            .then(function() {
              that.setState({
                submitted: true,
                successfull: true
              });
            })
            .catch(function(error) {
              that.setState({
                successfull: false
              });
            });
  }

  render() {

    return (
      <Container fluid="md" className="App">
        <Row>
          <Col>
            <div className="domain">
              {this.state.domain}
            </div>
            {this.state.submitted && this.state.successfull &&
              <Alert key={1} variant={'success'}>
                Your information has been sent to the seller, good luck :)
              </Alert>
            }
            {this.state.submitted && !this.state.successfull &&
              <Alert key={1} variant={'warning'}>
                Failed to send your information, please try again later.
              </Alert>
            }
          </Col>
        </Row>
  
        <Row>
          <Col>
            <p className="domain-sale">
              <b> is ready for sale.</b> <br />
              <b> Please leave your contact details:</b>
            </p>
          </Col>
        </Row>
  
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="domain__name domain__info">
            <input
              type="text"
              name="buyername"
              id="name"
              value={this.state.buyername}
              placeholder="your name"
              onChange={this.handleChange}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="domain__mobile domain__info">
            <input
              type="text"
              name="mobile"
              id="mobile"
              value={this.state.mobile}
              placeholder="mobile"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
  
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="domain__email domain__info">
            <input
              type="text"
              name="email"
              id="email"
              value={this.state.email}
              placeholder="your@email.com"
              onChange={this.handleChange}
            />
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} className="domain__price domain__info">
            <input
              type="text"
              name="price"
              id="price"
              value={this.state.price}
              placeholder="the price you offer"
              onChange={this.handleChange}
            />
          </Col>
        </Row>
  
        <Row>
          <Col>
            <select name="currency" id="currency" value={this.state.currency}
              onChange={this.handleChange}>
              <option value="usd">USD</option>
              <option value="aud">Australian Dollar</option>
            </select>
          </Col>
        </Row>
  
        <Row>
          <Col className="domain__message domain__info col-sm">
            <textarea
              name="message"
              id="message"
              cols="60"
              rows="60"
              value={this.state.message}
              placeholder="any more information you'd like to add"
              onChange={this.handleChange}
            ></textarea>
          </Col>
        </Row>
  
        <Row>
          <Col className="domain__buttons domain__info col-sm">
            <input type="button" disabled={this.state.submitted} value="Submit" onClick={this.handleSubmit} />
          </Col>
        </Row>
      </Container>
    );
  }
}
  

export default App;



// <Container fluid="md" className="App">
// <Row>
//   <Col>
//     <h2>
//       <span className="domain">{domain}</span>
//     </h2>
//   </Col>
// </Row>

// <Row>
//   <Col>
//     <p>
//       <b> is ready for sale.</b> <br />
//       <b> Please leave your contact details:</b>
//     </p>
//   </Col>
// </Row>

// <Row className="domain__form">
//   <Col xs={12} sm={12} md={6} lg={6} className="domain__name domain__info">
//     <input
//       type="text"
//       name="buyername"
//       id="name"
//       value={name}
//       placeholder="your name"
//     />
//   </Col>
//   <Col xs={12} sm={12} md={6} lg={6} className="domain__mobile domain__info">
//     <input
//       type="text"
//       name="mobile"
//       id="mobile"
//       value={mobile}
//       placeholder="mobile"
//     />
//   </Col>
// </Row>

// <Row>
//   <Col xs={12} sm={12} md={6} lg={6} className="domain__email domain__info">
//     <input
//       type="text"
//       name="email"
//       id="email"
//       value={email}
//       placeholder="your@email.com"
//     />
//   </Col>
//   <Col xs={12} sm={12} md={6} lg={6} className="domain__price domain__info">
//     <input
//       type="text"
//       name="price"
//       id="price"
//       value={price}
//       placeholder="the price you offer"
//     />
//   </Col>
// </Row>

// <Row>
//   <Col>
//     <select name="currency" id="currency">
//       <option selected value="usd">USD</option>
//       <option value="aud">Australian Dollar</option>
//     </select>
//   </Col>
// </Row>

// <Row>
//   <Col className="domain__message domain__info col-sm">
//     <textarea
//       name="message"
//       id="message"
//       cols="60"
//       rows="60"
//       value={message}
//       placeholder="any more information you'd like to add"
//     ></textarea>
//   </Col>
// </Row>

// <Row>
//   <Col className="domain__buttons domain__info col-sm">
//     <input type="button" value="Submit" />
//   </Col>
// </Row>
// </Container>